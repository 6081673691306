import React from 'react';
import styled from 'styled-components';
import { WrapMax } from '$components/Wraps';
import scalingSize from '$utils/scalingSize';
import Heading1 from '$components/Heading1';
import Heading2 from '$components/Heading2';
import Excerpt from '$components/Excerpt';
import FadeInSection from '$components/FadeInSection';
import Row from './Row';
import CtaButton from './CtaButton';
import { BlockStartPageHeroProps } from './types';

const Outer = styled.section`
  ${scalingSize('margin-top', 40, 88)};
  ${scalingSize('margin-bottom', 32, 72)}
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em;

  @media screen and (min-width: 860px) {
    grid-gap: 4em;
  }
`;

const BlockStartPageHero: React.FC<BlockStartPageHeroProps> = ({ h1, h2, content, ctaText, ctaTo }) => (
  <FadeInSection>
    <Outer>
      <Inner>
        <Heading1 startPage>{h1}</Heading1>
        <Row>
          <Heading2 startPage>{h2}</Heading2>
          <Excerpt startPage>{content}</Excerpt>
        </Row>
        <CtaButton showBtnArrow={false} to={ctaTo}>
          {ctaText}
        </CtaButton>
      </Inner>
    </Outer>
  </FadeInSection>
);

export default BlockStartPageHero;
