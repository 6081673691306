import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em;
  max-width: 61.625em;
  justify-self: center;
  align-items: center;

  @media screen and (min-width: 860px) {
    grid-gap: 4em 2.5em;
    grid-row: 2;
    grid-template-columns: 410fr 536fr;
  }
`;

const Row: React.FC = ({ children }) => <StyledRow>{children}</StyledRow>;

export default Row;
