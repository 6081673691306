import React from 'react';
import styled from 'styled-components';
import Button from '$components/Button';
import { CtaButtonProps } from './types';

const StyledButton = styled(Button)`
  margin: 1em 0 0;
  justify-self: start;

  @media screen and (min-width: 860px) {
    justify-self: center;
    margin-top: 0;
    grid-column: 1 / 2;
  }
`;

const CtaButton: React.FC<CtaButtonProps> = ({ children, to, showBtnArrow }) => (
  <StyledButton inline to={to} showArrow={showBtnArrow}>
    {children}
  </StyledButton>
);

export default CtaButton;
